import { AssetConf, TRarity } from "@pro/common/conf";
import React, { ReactNode } from "react";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import { getRarityColor } from "../../helpers";
import CabinetTabsStyles from "./CabinetTabs.module.scss";

const CabinetTabsView = ({tabPanelViews}: {tabPanelViews: ReactNode[]}) => {
	const tabs = AssetConf.RARITIES.map((it, index) => {
		return (
			<Tab className={CabinetTabsStyles.tabs_style}
			     key={`tab-${index}`}
			>
				<div className={CabinetTabsStyles.tab_square}
				     style={{background: getRarityColor(it as TRarity)}}/>
				{it.toUpperCase()}
			</Tab>
		);
	});

	const tabPanels = tabPanelViews.map((it, index) => {
		return (
			<TabPanel className={CabinetTabsStyles.lib_tab_page}
			          key={`tab-pane-${index}`}
			>
				{it}
			</TabPanel>
		);
	});

	return (
		<Tabs className={CabinetTabsStyles.tabs_container}
		      selectedTabClassName={CabinetTabsStyles.active}
		      selectedTabPanelClassName={CabinetTabsStyles.selected_tab_page}
		      forceRenderTabPanel={true}
		>
			<TabList className={CabinetTabsStyles.lib_tab_header}>
				{tabs}
			</TabList>
			{tabPanels}
		</Tabs>
	);
};

export default CabinetTabsView;
