import { TRarity } from "@pro/common/conf";

export const dropdownOptions = ["all", "common", "rare", "unique", "epic", "legendary", "mythic"]

export const rarityOptions = ["common", "rare", "unique", "epic", "legendary", "mythic"]

export const getRarityColor = (rarity: TRarity): string => {
	switch (rarity) {
		case "common":
			return "#DBDBDB";
		case "rare":
			return "#2d130e";
		case "unique":
			return "#1A5CD0";
		case "epic":
			return "#D92ED9";
		case "legendary":
			return "#D28F41";
		case "mythic":
			return "#C32222";
		default:
			return "#5E615C";
	}
}
