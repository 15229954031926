import { observer } from "mobx-react";
import React, { ReactNode } from "react";
import { useApp } from "../../App";
import ShouldLoginView from "./ShouldLoginView";

const EnsureLoginedView = observer(({children}: {children: ReactNode}) => {
	const app = useApp();

	return app.store.logined
		? <>{children}</>
		: <ShouldLoginView/>;

});

export default EnsureLoginedView;