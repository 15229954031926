import { IAssetJson } from "@pro/common/conf";
import classNames from "classnames";
import React from "react";
import Styles from "./Card.module.scss";

const CardView = ({data, amount}: {data: IAssetJson, amount: number}) => {
	const containerClass = classNames(Styles.container, {
		[Styles.disabled]: amount < 1,
	});

	return (
		<div className={containerClass}>
			<div className={Styles.amountContainer}>
				<div>Amount:</div>
				<div>{amount}</div>
			</div>
			<img className={Styles.image} src={data.img} alt="symbol image"/>
		</div>
	);
};

export default CardView;
