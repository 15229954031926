import { AssetConf } from "@pro/common/conf";
import { observer } from "mobx-react";
import React, { useEffect } from "react";
import { useApp } from "../../App";
import CabinetTabsView from "./CabinetTabsView";
import Styles from "./SymbolCollection.module.scss";
import CardView from "./CardView";

const SymbolCollectionView = observer(() => {
	const app = useApp();
	const tabPanelsView = AssetConf.RARITIES.map(rarity => {
		const symbolViews = app.assetConf.symbols
			.filter(symbol => symbol.rarity === rarity && symbol.unpack && !symbol.hidden)
			.map(it => {
				let symbolModels = app.store.symbols.queryByTemplateId(it.template_id);
				return <CardView data={it}
				                 amount={symbolModels.length}
				                 key={`symbol-view-${it.template_id}`}
				/>;
			});

		return (
			<div className={Styles.container}>
				{symbolViews}
			</div>
		);
	});

	useEffect(() => {
		app.worldService.updatePackAndAsset().catch();
	}, []);

	return (
		<CabinetTabsView tabPanelViews={tabPanelsView}/>
	);
});

export default SymbolCollectionView;
