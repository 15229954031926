import React from "react";
import { elastic as ElasticBurgerMenu, State as MenuState } from "react-burger-menu";
import { PagePath } from "../../helpers/PageHelper";
import { L } from "../../l10n/L10n";
import "../BurgerMenuLanding.scss";
import LoginContainerView, { LoginContainerStyle } from "../LoginContainerView";
import HeaderInternalLink from "../shared/HeaderInternalLink";
import MenuSocialLinks from "../shared/MenuSocialLinks";
import UnpackingLink from "../UnpackingLink";

type BurgerMenuState = {
	menuOpen: boolean
}

export class CabinetMenu extends React.Component<{}, BurgerMenuState>
{
	constructor(props: {})
	{
		super(props);
		this.state = {
			menuOpen: false,
		};
	}

	render()
	{
		return (
			<ElasticBurgerMenu
				right
				isOpen={this.state.menuOpen}
				onStateChange={(state: MenuState) => this.handleStateChange(state)}
			>
				<HeaderInternalLink href={PagePath.MAIN}>{L.page.main}</HeaderInternalLink>
				<HeaderInternalLink href={PagePath.CARDS}>{L.page.cards}</HeaderInternalLink>
				<HeaderInternalLink href={PagePath.BOOKS}>{L.page.books}</HeaderInternalLink>
				<HeaderInternalLink href={PagePath.HEROES}>{L.page.heroes}</HeaderInternalLink>
				<HeaderInternalLink href={PagePath.SPECIAL_NFT}>{L.specialEditionPage.title}</HeaderInternalLink>
				<UnpackingLink/>

				<LoginContainerView styleType={LoginContainerStyle.MENU}/>

				<MenuSocialLinks/>
			</ElasticBurgerMenu>
		);
	}

	private handleStateChange(state: MenuState)
	{
		this.setState({menuOpen: state.isOpen});
	}
}
