import { Link } from "gatsby";
import { observer } from "mobx-react";
import React from "react";
import { useApp } from "../App";
import { PagePath } from "../helpers/PageHelper";
import { L } from "../l10n/L10n";
import HeaderLinkStyles from "./shared/HeaderLink.module.scss";
import Styles from "./UnpackingLink.module.scss";

const UnpackingLink = observer(() => {
	const app = useApp();
	const packsAmount = app.store.packs.amount;

	return (
		<Link className={HeaderLinkStyles.headerLink} to={PagePath.PACKS}>
			<div className={Styles.unpacking_box}>
				<div>{L.packsPage.title}</div>
				<div className={Styles.amount_packs}
				     style={{
					     display: packsAmount > 0 ? "block" : "none",
				     }}
				>
					{packsAmount > 0 ? packsAmount : null}
				</div>
			</div>
		</Link>
	);
});

export default UnpackingLink;
