import React, { ReactNode } from "react";
import LayoutBase from "../shared/LayoutBase";
import CabinetHeader from "./CabinetHeader";
import { CabinetMenu } from "./CabinetMenu";

const CabinetLayout = ({children}: {children: ReactNode}) => (
	<LayoutBase
		header={<CabinetHeader/>}
		menu={<CabinetMenu/>}
	>
		{children}
	</LayoutBase>
);

export default CabinetLayout;
