import React from "react";
import Styles from "./CabinetTop.module.scss";
import LayoutStyles from "./CabinetLayout.module.scss";

const CabinetTopView = ({title, desc}: {title: string, desc: string}) => (
	<div className={Styles.back_container}>
		<div className={Styles.gradient}/>
		<div className={Styles.background}/>
		<div className={LayoutStyles.main_container}>
			<div className={Styles.title_container}>
				<h1 className={Styles.name_page}>{title}</h1>
				<p>{desc}</p>
			</div>
		</div>
	</div>
);

export default CabinetTopView;
