import React from "react";
import CabinetTopView from "../components/cabinet/CabinetTopView";
import EnsureLoginedView from "../components/cabinet/EnsureLoginedView";
import CabinetLayout from "../components/cabinet/CabinetLayout";
import SymbolCollectionView from "../components/cabinet/SymbolCollectionView";
import SEO from "../components/seo";

const CardsPage = () => (
	<CabinetLayout>
		<SEO title="Cards"
		     description="Tribal Books is a free-to-play online digital collectible nft-card game"/>

		<EnsureLoginedView>
			<CabinetTopView title="My Cards"
			                desc="Ancient civilizations created symbols to store their most important knowledge and descriptions of powerful magic in the sacred books of forces. The cards from the Ancient Symbols Collection have two major advantages: they are limited (won’t be reproduced in such a view in the future), they are universal (can interact with characters and books, that belong to different elements). Each of those cards is a treasure. Dispose of it wisely!"
			/>
			<SymbolCollectionView/>
		</EnsureLoginedView>
	</CabinetLayout>
);

export default CardsPage;