import React from "react";
import { PagePath } from "../../helpers/PageHelper";
import { L } from "../../l10n/L10n";
import LoginContainerView, { LoginContainerStyle } from "../LoginContainerView";
import HeaderContainer from "../shared/HeaderContainer";
import HeaderDecor from "../shared/HeaderDecor";
import HeaderInternalLink from "../shared/HeaderInternalLink";
import HeaderPanel from "../shared/HeaderPanel";
import UnpackingLink from "../UnpackingLink";

const CabinetHeader = () => (
	<HeaderContainer>
		<HeaderPanel>
			<HeaderInternalLink href={PagePath.MAIN}>{L.page.main}</HeaderInternalLink>
			<HeaderDecor/>
			<HeaderInternalLink href={PagePath.CARDS}>{L.page.cards}</HeaderInternalLink>
			<HeaderDecor/>
			<HeaderInternalLink href={PagePath.BOOKS}>{L.page.books}</HeaderInternalLink>
			<HeaderDecor/>
			<HeaderInternalLink href={PagePath.HEROES}>{L.page.heroes}</HeaderInternalLink>
			<HeaderDecor/>
			<HeaderInternalLink href={PagePath.SPECIAL_NFT}>{L.specialEditionPage.title}</HeaderInternalLink>
		</HeaderPanel>
		<HeaderPanel>
			<UnpackingLink/>
			<LoginContainerView styleType={LoginContainerStyle.HEADER}/>
		</HeaderPanel>
	</HeaderContainer>
);

export default CabinetHeader;
