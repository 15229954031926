import React from "react";
import LoginBtn from "../LoginBtn";
import Styles from "./ShouldLogin.module.scss";

const ShouldLoginView = () => (
	<div className={Styles.container}>
		<h1>You should be logged in to view this page</h1>
		<LoginBtn/>
	</div>
);

export default ShouldLoginView;
